.exhibition_title {
  @apply pt-1.5 pb-3 text-2xl tablet-lg:text-3xl font-normal hover:underline;
  line-height: 28px;
  a {
    @apply hover:no-underline space-y-2;
  }
}

.description {
  @apply text-xs font-medium space-y-3;

  div.show-details {
    @apply text-sm font-normal;
  }
}

.summary {
  a {
    @apply underline decoration-1 decoration-dotted underline-offset-2;
  }
}

.card {
  box-shadow: 0px 0px 12px 0px var(--Indigo-100, #e0e7ff) inset;
  &:hover {
    box-shadow: 0px 0px 18px 0px var(--Indigo-100, #e0e7ff) inset;
  }
}
